import styles from "./Cookies.module.css";

const CookiesPage = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.space}>Informacje o Cookies</h1>
      <div>
        <h3>Functionality_storage</h3>
        <p>
          Służy do umożliwienia poprawnego działania strony, zapamiętania preferencji użytkownika i dostosowania funkcji
          strony internetowej.
        </p>
      </div>

      <div>
        <h3>Security_storage</h3>
        <p>
          Zapewnia bezpieczeństwo użytkownika, pomagając w wykrywaniu oszustw oraz zabezpieczeniu przed nieautoryzowanym
          dostępem.
        </p>
      </div>

      <div>
        <h3>Personalization_storage</h3>
        <p>
          Umożliwia dostosowanie treści strony internetowej do preferencji użytkownika, takich jak wybór języka czy
          układu strony.
        </p>
      </div>

      <div>
        <h3>Ad_storage</h3>
        <p>
          Przechowuje dane dotyczące reklam, umożliwiając wyświetlanie spersonalizowanych treści reklamowych oraz
          optymalizację kampanii reklamowych.
        </p>
      </div>

      <div>
        <h3>Ad_user_data</h3>
        <p>
          Zawiera dane użytkownika, które pomagają w personalizacji reklam w zależności od zainteresowań oraz
          wcześniejszych interakcji.
        </p>
      </div>

      <div>
        <h3>Ad_personalization</h3>
        <p>
          Pliki cookie służące do personalizacji reklam, aby były bardziej dopasowane do użytkownika, na podstawie jego
          działań na stronie.
        </p>
      </div>

      <div>
        <h3>Analytics_storage</h3>
        <p>Zbierają dane statystyczne, które pomagają w analizie ruchu na stronie i w optymalizacji jej działania.</p>
      </div>
    </div>
  );
};
export default CookiesPage;
