import React from "react";

export const convertToParagraphs = (text: string) => {
  // Split the text into lines based on <br /> tags
  const lines = text.split(/<br\s*\/?>/);

  // Map each line into a React fragment, handling special HTML tags
  return lines.map((line, index) => {
    // Check if the line is empty and it's not the last one, then return a <br /> tag
    if (line.trim() === "" && index !== lines.length - 1) {
      return <br key={index} />;
    }

    // Replace <b> tags with corresponding React elements
    const formattedLine = line.replace(/<b>(.*?)<\/b>/g, (match, p1) => `<strong>${p1}</strong>`);

    return (
      <React.Fragment key={index}>
        {/* Use dangerouslySetInnerHTML to render the HTML */}
        <span dangerouslySetInnerHTML={{ __html: formattedLine }} />
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    );
  });
};

export const convertToParagraphs2 = (text: string) => {
  // Replace \r\n\r\n with a <br /> tag
  const formattedText = text
    .replace(/\r\n\r\n/g, "<br />") // Replace double newlines with <br />
    .replace(/\r\n/g, " ") // Replace single newline with a space to maintain word spacing
    .replace(/<b>(.*?)<\/b>/g, "<strong>$1</strong>") // Replace <b> tags with <strong> tags
    .replace(/<u>(.*?)<\/u>/g, "<u>$1</u>"); // Ensure underline tags are preserved

  // Split the text into an array of paragraphs at each <br />
  const paragraphs = formattedText.split(/<br\s*\/?>/);

  // Map each paragraph to a <p> element, handling special HTML tags
  return paragraphs.map((paragraph, index) => (
    <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
  ));
};

