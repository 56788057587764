import React, { useRef, useEffect, useState } from "react";
import styles from "./News.module.css";
import type { NewsCategory } from "../../../../types/News";
import { CategoryTag } from "../../CategoryTag/CategoryTag";
import { Link, useNavigate } from "react-router-dom";
import useBreakpoint from "../../../../hooks/useBreakpoints";

const lorem =
'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti modi fugit provident. Harum accusamus dolore rem deserunt recusandae iusto veniam aspernatur maxime fugiat, eius facilis minus amet culpa. Officiis, ad ipsum tempora soluta reiciendis minus, inventore voluptas quas labore, itaque non reprehenderit adipisci et maiores sint suscipit recusandae hic mollitia nihil unde quam modi obcaecati. Voluptatibus sapiente obcaecati voluptate repellat. Possimus eum, similique cupiditate porro explicabo, maiores corrupti eveniet ipsa optio deserunt nisi cumque ipsum temporibus consequuntur dolorem sequi est quibusdam repellendus sit enim veritatis odit! Accusamus, laborum deleniti accusantium porro cupiditate delectus eligendi, dolores dolorum odio, architecto ullam reprehenderit!'

const formatDate = (dateString: string) => {
  return dateString.split(" ")[0];
};

// Truncates the text to fit within the container's width
export const truncateTextToFit = (text: string, containerWidth: number, fontSize: string, fontFamily: string) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context!.font = `${fontSize} ${fontFamily}`;

  let truncatedText = text;
  while (context!.measureText(truncatedText).width > containerWidth) {
    truncatedText = truncatedText.slice(0, -1);
  }

  return truncatedText.length < text.length ? truncatedText + "..." : text;
};

// Cleans the text by removing HTML tags
export const cleanString = (text: string): string => {
  const cleanedText = text.replace(/<br\s*\/?>/gi, "").replace(/<\/?[^>]+(>|$)/g, "");
  return cleanedText.replace(/[\r\n]+/g, " ").trim();
};

export const truncateTextByWords = (text: string, numWords: number): string => {
  const wordsArray = text.split(" ");

  // If the text has fewer words than the limit, return it as is
  if (wordsArray.length <= numWords) {
    return text;
  }

  // Slice the text to the specified number of words and add "..."
  const truncatedText = wordsArray.slice(0, numWords).join(" ");

  return truncatedText + "...";
};

interface NewsProps {
  cover: string;
  category: NewsCategory;
  description: string;
  date: string;
  title: string;
  link?: string;
  loading: boolean;
}

export const News = ({ cover, category, description, date, title, link, loading }: NewsProps) => {
  const navigate = useNavigate();
  const [truncatedDescription, setTruncatedDescription] = useState<string>(cleanString(description));
  const isSmall = useBreakpoint(400);

  useEffect(() => {
    setTruncatedDescription(isSmall ? truncateTextByWords(cleanString(description), 30) : cleanString(description));
  }, [isSmall, description]);

  return (
    <article className={styles.container}>
      <Link to={`/news/${link}`} className={styles.link}>
        <div className={styles.imageContainer}>
          <img
            src={!loading ? cover : "/mockup-background.jpg"}
            alt=""
            className={`${cover ? styles.image : styles.imageMockup}`}
          />
          <CategoryTag category={category} size="small" />
        </div>
        <div className={styles.contentContainer}>
          <p className={`${styles.title} ${loading&&"elementLoadingState"}`}>{title}</p>
          <div className={styles.descriptionContainer}>
            <p className={`text-big ${loading&&"elementLoadingState"}`}>{truncatedDescription}</p>
          </div>
          <p className={`text-small ${styles.date} ${loading&&"elementLoadingState"}`}>{date}</p>
        </div>
      </Link>
    </article>
  );
};
