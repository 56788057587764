import React from "react";
import styles from "./CategoryTag.module.css";
import type { NewsCategory } from "../../../types/News";

const newsCategoryText: { [key in NewsCategory]: string } = {
  "manga-rynek-polski": "MANGA - RYNEK POLSKI",
  "manga-rynek-zagraniczny": "MANGA - RYNEK ZAGRANICZNY",
  ciekawostki: "CIEKAWOSTKI",
  konwenty: "KONWENTY",
  anime: "ANIME",
  inne: "INNE",
  mockup: "MOCKUP",
};

interface CategoryTagProps {
  category: NewsCategory;
  size: "big" | "small";
}

const getCategoryClassName = (category: NewsCategory, size: "big" | "small") => {
  return `${styles[`slideCategory--${category}`]} ${styles[`slideCategory--${size}`]}`;
};

export const CategoryTag = ({ category, size }: CategoryTagProps) => {
  return (
    <p className={`${styles.slideCategory} ${getCategoryClassName(category, size)}`}>
      {newsCategoryText[category]}
    </p>
  );
};
