import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css"; // If you have a global CSS file
import { Helmet, HelmetProvider } from "react-helmet-async";
import ConsentBanner from "./components/ConsentBanner/ConsentBanner";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
