// src/components/OpenConsentButton.tsx
import React from 'react';

const OpenConsentButton: React.FC = () => {
  return (
    <div id="open-consent-banner">
    </div>
  );
};

export default OpenConsentButton;
