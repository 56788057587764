// src/components/News/Searchbar/Searchbar.tsx
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Searchbar.module.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

export const Searchbar = () => {
  // Inicjalizacja searchParams i searchTerm z URL lub pustym ciągiem
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("search") || "");

  // Funkcja obsługująca wyszukiwanie, aktualizacja parametrów wyszukiwania w URL
  const handleSearch = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      search: searchTerm,
      page: "1", // Set page to 1 when a search is performed
    });
    // setSearchTerm('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={styles.container}>
      {/* Pole input do wprowadzania frazy wyszukiwania */}
      <label htmlFor="search-input" className={styles.hiddenLabel}>
        Search
      </label>
      <input
        type="text"
        className={styles.searchInput}
        placeholder="Wyszukaj frazę"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        aria-label="Search"
        onKeyDown={handleKeyPress}
      />
      {/* Przycisk wyszukiwania */}
      <button className={styles.searchButton} onClick={handleSearch}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </div>
  );
};
