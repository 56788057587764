import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Navigation.module.css";
import logo from "../../styles/assets/logo.png";
import useBreakpoint from "../../hooks/useBreakpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const MobileNavigation = ({
  isOpen,
  closeMenu,
}: {
  isOpen: boolean;
  closeMenu: () => void;
}) => {
  return (
    <div
      className={`${styles.mobileNavigation} ${
        isOpen ? styles.open : styles.close
      }`}
      onClick={closeMenu}
    >
      <a href="/news">
        <h3>Newsy</h3>
      </a>
      <Link to="/magazyn">
        <h3>Magazyn Otaku</h3>
      </Link>
      <Link to="/redakcja">
        <h3>Redakcja</h3>
      </Link>
    </div>
  );
};

export const Navigation = () => {
  const navRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isSmall = useBreakpoint("s");
  const menuRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNewsClick = () => {
    // Reset all search params
    setSearchParams(new URLSearchParams());
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (!isSmall) {
      closeMenu();
    }
  }, [isSmall]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.clientY > 100 &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    closeMenu();
    navigate("/");
  };

  return (
    <div className={styles.navigation}>
      <div className={styles.container}>
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
          onClick={handleLogoClick}
        />
        <div className={styles.links}>
          {isSmall ? (
            <FontAwesomeIcon
              icon={isMenuOpen ? faXmark : faBars}
              className={styles.hamburgerIcon}
              onClick={toggleMenu}
            />
          ) : (
            <ul className={styles.menu}>
              <li className={styles.menuItem}>
                <a href="/news" onClick={handleNewsClick}>
                  <h3>Newsy</h3>
                </a>
              </li>
              <li className={styles.menuItem}>
                <Link to="/magazyn">
                  <h3>Magazyn Otaku</h3>
                </Link>
              </li>
              <li className={styles.menuItem}>
                <Link to="/redakcja">
                  <h3>Redakcja</h3>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      {isSmall && (
        <div ref={menuRef}>
          <MobileNavigation isOpen={isMenuOpen} closeMenu={closeMenu} />
        </div>
      )}
    </div>
  );
};

//todo: H1, H2, H3... do ostylowania globalnie
//todo: datk/light mode
//todo: animacja ikony
//todo: handleNewsClick