import { useState, useEffect } from "react";
import { fetchBanners } from "../API/API";
import type { Banners } from "../types/newsAPI";

const useFetchBanners = () => {
  const [banners, setBanners] = useState<Banners[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBannersData = async () => {
      setLoading(true);

      try {
        const result = await fetchBanners();
        const banners = result.data;

        setBanners(banners);
        setError(null);
      } catch (error: any) {
        setError(error.message);
        setBanners([]);
      } finally {
        setLoading(false);
      }
    };
    fetchBannersData();
  }, []);
  return { banners, error, loading };
};

export default useFetchBanners;
