import styles from "./NewsHeader.module.css";

interface NewsHeaderProps {
  title: string;
  subtitle: string;
  postedBy: string;
  date: string;
  loading: boolean;
}

export const NewsHeader = ({
  title,
  subtitle,
  postedBy,
  date,
  loading,
}: NewsHeaderProps) => {
  return (
    <section className={styles.container}>
      <h2 className={`${loading && "textTransparent"}`}>{title}</h2>
      <p className={`text-big ${loading && "textTransparent"}`}>{subtitle}</p>
      <div className={styles.separator}></div>
      <p className={`text-small ${styles.author} ${loading && "textTransparent"}`}>
        Dodane przez <span>{postedBy}</span>, {date}
      </p>
    </section>
  );
};
