// src/components/News/Tags/Tag/Tag.tsx
import React from "react";
import styles from "./Tag.module.css";

export type Category =
  | "manga-rynek-polski"
  | "anime"
  | "inne"
  | "manga-rynek-zagraniczny"
  | "ciekawostki"
  | "konwenty";

interface TagProps {
  category: Category;
  onClick: () => void;
  selected: boolean;
}

const categoryColors = {
  ["manga-rynek-polski"]: styles.gold,
  anime: styles.green,
  inne: styles.blue,
  ["manga-rynek-zagraniczny"]: styles["light-blue"],
  ciekawostki: styles.pink,
  konwenty: styles.violet,
};

export const Tag = ({ category, onClick, selected }: TagProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={`${styles.dot} ${categoryColors[category]}`}></div>
      <p
        className={`text-big ${styles.text} ${
          selected ? styles.bold : styles.normal
        }`}
      >
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </p>
    </div>
  );
};
