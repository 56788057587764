import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop: React.FC = () => {
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    // Get the scrollable element
    const scrollingElement = document.scrollingElement || document.documentElement;

    // Scroll to top
    scrollingElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname, search, hash]);

  return null;
};

export default ScrollToTop;
