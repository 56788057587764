import styles from "./TitleWithSplit.module.css";

type TitleWithSplitProps = {text: string}

export const TitleWithSplit = ({ text }: TitleWithSplitProps) => {
  return (
    <header className={styles.container}>
      <div className={styles.splitter} aria-hidden="true"></div>
      <h1 className={styles.title}>{text}</h1>
    </header>
  );
};
