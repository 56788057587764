// src/hooks/useBreakpoint.ts
import { useState, useEffect } from 'react';
import { breakpoints } from '../utils/breakpoints';

type Breakpoint = keyof typeof breakpoints | number;

const getBreakpointValue = (breakpoint: Breakpoint): number => {
  if (typeof breakpoint === 'number') {
    return breakpoint;
  }
  return breakpoints[breakpoint];
};

const useBreakpoint = (breakpoint: Breakpoint) => {
  const [isBelowBreakpoint, setIsBelowBreakpoint] = useState(window.innerWidth < getBreakpointValue(breakpoint));

  useEffect(() => {
    const handleResize = () => {
      setIsBelowBreakpoint(window.innerWidth < getBreakpointValue(breakpoint));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return isBelowBreakpoint;
};

export default useBreakpoint;
