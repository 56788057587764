import { Kategorie } from "../types/newsAPI";

export const categoriesMockupData = [
  {
    id: 1,
    nazwa: "Anime",
    alias: "anime",
    kolor: "#228B22",
  },
  {
    id: 2,
    nazwa: "Manga - rynek polski",
    alias: "manga-rynek-polski",
    kolor: "#FFA500",
  },
  {
    id: 3,
    nazwa: "Manga - rynek zagraniczny",
    alias: "manga-rynek-zagraniczny",
    kolor: "#ADD8E6",
  },
  {
    id: 4,
    nazwa: "Konwenty",
    alias: "konwenty",
    kolor: "#CF9FFF",
  },
  {
    id: 5,
    nazwa: "Ciekawostki",
    alias: "ciekawostki",
    kolor: "#EA85B1",
  },
  {
    id: 6,
    nazwa: "Inne",
    alias: "inne",
    kolor: "#0000FF",
  },
] as Kategorie[];
