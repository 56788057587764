import React, { useEffect } from "react";
import styles from "./Slide.module.css";
import type { NewsCategory } from "../../../../../types/News";
import { CategoryTag } from "../../../CategoryTag/CategoryTag";
import { Link, useNavigate } from "react-router-dom";

interface SlideProps {
  cover: string;
  category: NewsCategory;
  description: string;
  link: string;
  id: number;
  loading: boolean;
}

const truncateText = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  }
  return text;
};
const getSlugFromLink = (link: string) => {
  const baseUrl = "https://nowe.otaku.pl/news/";
  return link.replace(baseUrl, "");
};

export const Slide = ({ cover, category, description, link, id, loading }: SlideProps) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    const slug = getSlugFromLink(link);
    navigate(`/news/${slug}`);
  };

  return (
    <article
      className={styles.slideContainer}
      style={{ backgroundImage: cover ? `url(${cover})` : `url(/mockup-background.jpg)` }}
      role="button"
      aria-label={`Read more about ${truncateText(description, 20)}`}
      onClick={navigateTo}
    >
      <Link to={`/news/${link}`} style={{ backgroundImage: cover ? cover : "" }} className={styles.link}>
        <div className={styles.coverImage} style={{ backgroundImage: `url(${cover})` }}>
          {description && <div className={styles.shadow} aria-hidden="true"></div>}
        </div>
        {description && (
          <section className={styles.textContainer}>
            <p className={`${styles.slideDescription} ${loading ? styles.slideDescriptionLoading : ""}`}>
              {truncateText(description, 150)}
            </p>
          </section>
        )}
      </Link>
    </article>
  );
};
