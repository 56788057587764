export const BannersMockupData = [
    {
        id: -1,
        url: '/mockup-background.jpg',
        link_zewnetrzny: false,
        obrazek: '/mockup-background.jpg',
        podpis: 'test',
        kategoria: {
            id: 0,
            nazwa: "mockup",
            alias: "mockup",
            kolor: "#FFA500",
          },
    },
    {
        id: -1,
        url: '/mockup-background.jpg',
        link_zewnetrzny: false,
        obrazek: '/mockup-background.jpg',
        podpis: 'test',
        kategoria: {
            id: 0,
            nazwa: "mockup",
            alias: "mockup",
            kolor: "#FFA500",
          },
    },
]