// src/components/News/Tags/Tags.tsx
import { Category, Tag } from "../Tag/Tag";
import styles from "./Tags.module.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import type { Kategorie } from "../../../../types/newsAPI";
import { categoriesMockupData } from "../../../../constants/categoriesMockupData";

interface TagsProps {
  kategorie: Kategorie[];
}

export const Tags = (props: TagsProps) => {
  const { kategorie } = props;
  const [categories, setCategories] = useState<Kategorie[]>(categoriesMockupData);
  // Inicjalizacja parametrów wyszukiwania z URL
  const [searchParams, setSearchParams] = useSearchParams();
  // Pobranie wybranych tagów z URL lub ustawienie pustej tablicy
  const selectedTagsFromUrl = searchParams.get("tags")?.split(",") || [];
  // Ustawienie stanu dla wybranych tagów
  const [selectedTags, setSelectedTags] =
    useState<string[]>(selectedTagsFromUrl);

  useEffect(()=>{
    if(kategorie && kategorie.length>0){
      setCategories(kategorie)
    }
  },[kategorie])

  useEffect(() => {
    // Aktualizacja URL w zależności od stanu wybranych tagów
    if (selectedTags.length === 0) {
      searchParams.delete("tags");
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tags: selectedTags.join(","),
      });
    }
  }, [selectedTags, searchParams, setSearchParams]);

  // Funkcja obsługująca kliknięcia na tagi
  const handleTagClick = (category: string) => {
    setSelectedTags((prevSelectedTags) => {
      const updatedTags = prevSelectedTags.includes(category)
        ? prevSelectedTags.filter((tag) => tag !== category)
        : [...prevSelectedTags, category];
      
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tags: updatedTags.join(","),
        page: "1", // Set page to 1 when a tag is clicked
      });
  
      return updatedTags;
    });
  };

  return (
    <div className={styles.container}>
      {categories.map((category, index) => (
        // Renderowanie tagów i przekazywanie właściwości do komponentu Tag
        <Tag
          key={index}
          category={category.alias as Category}
          onClick={() => handleTagClick(category.alias)}
          selected={selectedTags.includes(category.alias)}
        />
      ))}
    </div>
  );
};
