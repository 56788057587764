import images from '../styles/assets/mockup-background.jpg'
export const newsDetailsMockupData = {
  id: -1,
  temat: "Najnowszy news ze świata anime",
  alias: "najnowszy-news-ze-swiata-anime",
  tresc:
    "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
  tresc_preview:
    "Śledź najnowsze wiadomości o anime, zapowiedzi nowych sezonów, recenzje, i więcej.",
  data: "2024-08-02 00:00:00",
  obrazek: "/mockup-background.jpg",
  autor: {
    id: 0,
    nick: "Otaku",
  },
  kategoria: {
    id: 0,
    nazwa: "mockup",
    alias: "mockup",
    kolor: "#FFA500",
  },
  banery:[
    {
      obrazek: '/mockup-background.jpg',
      url: '/mockup-background.jpg'
    },
    {
      obrazek: '/mockup-background.jpg',
      url: '/mockup-background.jpg'
    },
    {
      obrazek: '/mockup-background.jpg',
      url: '/mockup-background.jpg'
    },
  ]
};
