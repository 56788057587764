import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Pagination.module.css";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface PaginationProps {
  total: number; // Total number of pages
}

export const Pagination: React.FC<PaginationProps> = ({ total }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageFromUrl = parseInt(searchParams.get("page") || "1", 10);
  const [current, setCurrent] = useState(currentPageFromUrl);

  // Listen for changes in the search params and update the current page
  useEffect(() => {
    if (currentPageFromUrl !== current) {
      setCurrent(currentPageFromUrl);
    }
  }, [currentPageFromUrl]);

  // Update search params whenever current page changes
  useEffect(() => {
    updateSearchParams();
  }, [current]);

  const updateSearchParams = () => {
    if (current === 1) {
      searchParams.delete("page"); // Remove 'page' param if it's 1
    } else {
      searchParams.set("page", String(current)); // Set 'page' param to the current page number
    }
    setSearchParams(searchParams);
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= total) {
      setCurrent(page); // Update the state with the new page number
    }
  };

  const renderPageButton = (page: number) => (
    <button
      key={`page-${page}`}
      onClick={() => handlePageChange(page)}
      className={`${styles.pageButton} ${current === page ? styles.active : ""}`}
    >
      {page < 10 ? `0${page}` : page}
    </button>
  );

  const renderPages = () => {
    if (total === 0) {
      return (
        <button className={styles.pageButton} disabled>
          00
        </button>
      );
    }

    if (total === 1) {
      return renderPageButton(1);
    }

    const pages = [];
    pages.push(renderPageButton(1));

    if (current > 3) {
      pages.push(<span key="start-ellipsis" className={styles.ellipsis}>...</span>);
    }

    const startPage = Math.max(2, current - 1);
    const endPage = Math.min(total - 1, current + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(renderPageButton(i));
    }

    if (current < total - 2) {
      pages.push(<span key="end-ellipsis" className={styles.ellipsis}>...</span>);
    }

    pages.push(renderPageButton(total));
    return pages;
  };

  return (
    <div className={styles.pagination}>
      <button
        className={styles.arrowButton}
        onClick={() => handlePageChange(current - 1)}
        disabled={current === 1 || total === 0}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      {renderPages()}

      <button
        className={styles.arrowButton}
        onClick={() => handlePageChange(current + 1)}
        disabled={current === total || total === 0}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};
