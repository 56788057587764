import React, { Suspense, useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import styles from "./News.module.css";
import { Searchbar } from "../../components/News/Searchbar/Searchbar";
import { Category, Tag } from "../../components/News/Tags/Tag/Tag";
import { Tags } from "../../components/News/Tags/Tags/Tags";
import { News as NewsItem } from "../../components/MainPage/NewsGrid/News/News";
import { Pagination } from "../../components/News/Pagination/Pagination";
import { useSearchParams } from "react-router-dom";
import { fetchNews } from "../../API/API";
import { Kategorie as TKategorie, News as TNews, Pagination as TPagination } from "../../types/newsAPI";
import useFetchNews from "../../hooks/useFetchNews";
import type { News as TypeNews } from "../../types/newsAPI";
import { MainPageNewsMockupData } from "../../constants/MainPageNewsMockupData";
import { Helmet } from "react-helmet-async";

const description =
  "Przeglądaj wszystkie wiadomości ze świata mangi i anime. Aktualne informacje, recenzje, wywiady i wiele więcej z podziałem na kategorie.";

const NewsContent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<TypeNews[]>(MainPageNewsMockupData);

  const tags = searchParams.get("tags") || "";
  const search = searchParams.get("search") || "";
  const page = searchParams.get("page") || "1";


  //! TU ZMIENIC LIMIT Z 6 NA 8 JAK BĘDZIE WIĘCEJ NEWSÓW
  const { kategorie, newsy, pagination, error, loading } = useFetchNews({
    strona: parseInt(page, 10),
    limit: 6,
    szukaj: search,
    kategorie: tags,
  });

  useEffect(() => {
    if (!loading) {
      console.log(newsy)
      setData(newsy);
    }
  }, [kategorie, newsy, pagination, loading]);

  return (
    <>
      <Helmet>
        <title>Newsy | Magazyn Otaku</title>
        <meta name="description" content={description} />
      </Helmet>
      <main>
        <Grid container spacing={2} className={styles.container}>
          <Grid lg={8} md={8} xs={12} className={styles.tagsContainer}>
            <Tags kategorie={kategorie} />
          </Grid>
          <Grid lg={4} md={4} xs={12} className={styles.searchbarContainer}>
            <Searchbar />
          </Grid>
          <Grid lg={12} md={12} xs={12} className={styles.mainContentContainer} container spacing={2}>
            {data.map((newsItem, index) => (
              <Grid lg={6} xs={12} key={index}>
                <NewsItem
                  cover={newsItem.obrazek}
                  category={newsItem.kategoria.alias as Category}
                  description={newsItem.tresc_preview}
                  date={newsItem.data}
                  title={newsItem.temat}
                  loading={loading}
                  link={`${newsItem.alias}-${newsItem.id}`}
                />
              </Grid>
            ))}
            {data.length <= 0 && (
              <p className={styles.informText}>Brak newsów dla wybranych parametrów wyszukiwania</p>
            )}
          </Grid>
          <Grid xs={12}>{newsy.length > 0 && <Pagination total={pagination?.ilosc_stron ?? 0} />}</Grid>
        </Grid>
      </main>
    </>
  );
};

const News: React.FC = () => {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <NewsContent />
    </Suspense>
  );
};

export default News;
