import styles from "./FowardButton.module.css";
import { Link } from "react-router-dom";

interface ForwardButtonProps {
  type: "news" | "magazyn";
}

const newsPath = "/news";
const magazynPath = "/magazyn";

const newsButtonContent = "PRZEJDŹ DO NEWSÓW";
const magazynButtonContent = "SPRAWDŹ WSZYSTKIE";

export const FowardButton = ({ type }: ForwardButtonProps) => {
  const href = type === "news" ? newsPath : magazynPath;
  const inputText = type === "news" ? newsButtonContent : magazynButtonContent;

  return (
    <div className={styles.container}>
      <Link to={href} className={styles.link}>
        {inputText}
      </Link>
    </div>
  );
};
