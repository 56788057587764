import styles from "./Slider.module.css";
//@ts-ignore
import Slider from "react-slick";
import { Slide } from "../Slide/Slide";
import { Kategorie, News } from "../../../../../types/newsAPI";
import { NewsCategory } from "../../../../../types/News";
import { useEffect, useMemo, useState } from "react";
import { MainPageNewsMockupData } from "../../../../../constants/MainPageNewsMockupData";
import { Banners } from "../../../../../types/newsAPI";
import { BannersMockupData } from "../../../../../constants/BannersMockupData";

interface BannerSliderProps {
  banery: Banners[];
  loading: boolean;
}

const cleanString = (text: string): string => {
  const cleanedText = text.replace(/<br\s*\/?>/gi, "").replace(/<\/?[^>]+(>|$)/g, "");
  return cleanedText.replace(/[\r\n]+/g, " ").trim();
};

export const BannerSlider = (props: BannerSliderProps) => {
  const [data, setData] = useState<Banners[]>(BannersMockupData);
  const { banery, loading } = props;

  const isMultipleSlides = useMemo(() => data.length > 1, [data.length]);

  useEffect(() => {
    if (banery && banery.length > 0) {
      setData(banery);
    }
  }, [banery]);

  var settings = {
    dots: isMultipleSlides,
    infinite: isMultipleSlides,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    pauseOnHover: true,
    accesibility: true,
  };

  return (
    <Slider {...settings} className={styles.sliderContainer} style={{marginBottom: isMultipleSlides?"40px":"10px"}}>
      {data.map((baner, index) => {
        return (
          <Slide
            cover={baner.obrazek}
            category={baner.kategoria?.alias ? (baner.kategoria.alias as NewsCategory) : "anime"}
            description={cleanString(baner.podpis)}
            link={baner.url}
            key={`${baner.id}-${index}`}
            id={baner.id}
            loading={loading}
          />
        );
      })}
    </Slider>
  );
};
