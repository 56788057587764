import styles from "./NewsGrid.module.css";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { News } from "../News/News";
import { News as TNews } from "../../../../types/newsAPI";
import { useEffect, useState } from "react";
import { MainPageNewsMockupData } from "../../../../constants/MainPageNewsMockupData";
import type { NewsCategory } from "../../../../types/News";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery

interface NewsGridProps {
  news: TNews[];
  loading: boolean;
}

export const NewsGrid = (props: NewsGridProps) => {
  const [data, setData] = useState<TNews[]>(MainPageNewsMockupData);
  const { news, loading } = props;

  

  // Use useMediaQuery to detect screen width
  const isSmallScreen = useMediaQuery("(max-width:400px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");
  const isLargeScreen = useMediaQuery("(max-width:1536px)");

  useEffect(() => {
    if (news && news.length > 0) {
      setData(news);
    }
    console.log(news)
  }, [news]);

  // Determine the displayedData based on screen size
  let displayedData = data;

  if (isSmallScreen) {
    // Screen width less than 400px
    displayedData = data.slice(0, Math.max(0, data.length - 4));
  } else if (isMediumScreen) {
    // Screen width less than 900px but greater than or equal to 400px
    displayedData = data.slice(0, 4);
  } else if (isLargeScreen) {
    // Screen width less than 1536px but greater than or equal to 900px
    displayedData = data.slice(0, 8);
  } else {
    // Screen width 1536px or larger
    displayedData = data;
  }
  //! TU USUNĄĆ .slice(0,6) jak będzie więcej newsów
  return (
    <Grid container spacing={2} className={styles.container}>
      {displayedData.slice(0,6).map((news, index) => {
        return (
          <Grid xs={12} md={6} xl={4} key={`${news.id}-${index}`} className="grid-news-item">
            <News
              cover={news.obrazek}
              category={news.kategoria.alias as NewsCategory}
              description={news.tresc_preview}
              date={news.data}
              title={news.temat}
              link={`${news.alias}-${news.id}`}
              loading={loading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
