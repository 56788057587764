import React, { useState, useEffect } from "react";
import { fetchNewsDetails } from "../API/API";
import { NewsDetailResponse, NewsItem } from "../types/NewsDetailsAPI";

export const useFetchNewsDetail = (newsId: number) => {
  const [currentNews, setCurrentNews] = useState<NewsItem | null>(null);
  const [previousNews, setPreviousNews] = useState<NewsItem | null>(null);
  const [nextNews, setNextNews] = useState<NewsItem | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchNewsDetailData = async () => {
      setLoading(true);

      try {
        const result = await fetchNewsDetails({ id: newsId });
        const { data }: NewsDetailResponse = result;

        setCurrentNews({
          id: data.id,
          temat: data.temat,
          alias: data.alias,
          tresc: data.tresc,
          obrazek: data.obrazek,
          data: data.data,
          autor: data.autor,
          kategoria: data.kategoria,
          banery: data.banery,
          tresc_preview: data.tresc_preview
        });

        setPreviousNews(data.poprzedni || null);
        setNextNews(data.nastepny || null);

        setError(null);
      } catch (error: any) {
        setError(error.message);
        setCurrentNews(null);
        setPreviousNews(null);
        setNextNews(null);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsDetailData();
  }, [newsId]);

  return { currentNews, previousNews, nextNews, error, loading };
};
