// src/App.tsx

import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import News from "./pages/News/News";
import NewsDetails from "./pages/NewsDetails/NewsDetails2";
import Magazine from "./pages/Magazine/Magazine";
import MagazineDetails from "./pages/MagazineDetails/MagazineDetails2";
import Redakcja from "./pages/Redakcja/Redakcja";
import Layout from "./components/Layout/Layout";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import NotFound from "./pages/NotFound/NotFound";
import { checkCookie, setCookie } from "./helpers/cookies";
import ReactGA from 'react-ga4'; // Import Google Analytics

import "./styles/globals.css";
import CookiesPage from "./pages/Cookies/Cookies";
import OpenConsentButton from "./components/OpenConsentBanner/OpenConsentBanner";

const Loader = () => <p>Loading...</p>;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Layout>
          <OpenConsentButton />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsDetails />} />
            <Route path="/info-cookies" element={<CookiesPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Suspense>
    </Router>
  );
}

export default App;
