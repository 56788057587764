import { useState, useEffect, useMemo } from "react";
import { fetchNews } from "../API/API";
import { Kategorie, News, Pagination as TPagination } from "../types/newsAPI";

interface FetchNewsParams {
  strona: number;
  limit: number;
  szukaj: string;
  kategorie: string;
}

const useFetchNews = (params: FetchNewsParams) => {
  const [kategorie, setKategorie] = useState<Kategorie[]>([]);
  const [newsy, setNewsy] = useState<News[]>([]);
  const [pagination, setPagination] = useState<TPagination | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Memoize params so that useEffect is not triggered unnecessarily
  const memoizedParams = useMemo(() => params, [params.strona, params.limit, params.szukaj, params.kategorie]);

  useEffect(() => {
    const fetchNewsData = async () => {
      setLoading(true);
      try {
        const result = await fetchNews(memoizedParams);
        const { kategorie, newsy, paginacja } = result.data;

        setKategorie(kategorie);
        setNewsy(newsy);
        setPagination(paginacja);

        setError(null);
      } catch (err: any) {
        setError(err.message);
        setKategorie([]);
        setNewsy([]);
        setPagination(null);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, [memoizedParams]);

  return { kategorie, newsy, pagination, error, loading };
};

export default useFetchNews;
