import React, { Suspense, useEffect } from "react";
import { createResource, fetchMainData } from "../../utils/fetchData";
import { Navigation } from "../../components/Navigation/Navigation";
import WindowWidth from "../../components/WindowWidth/WindowWidth";
import { TitleWithSplit } from "../../components/MainPage/TitleWithSplit/TittleWithSplit";
import { MainPageBanner } from "../../components/MainPage/Banner/Banner/Banner";
import { NewsGrid } from "../../components/MainPage/NewsGrid/NewsGrid/NewsGrid";
import { FowardButton } from "../../components/MainPage/FowardButton/FowardButton";
// import { MagazineGrid } from "../../components/MainPage/MagazineGrid/MagazineGrid/MagazineGrid";
import useFetchNews from "../../hooks/useFetchNews";
import { MagazineGrid } from "../../components/MainPage/MagazineGrid2/MagazineGrid";
import { useFetchMagazines } from "../../hooks/useFetchMagazines";
import { Helmet } from "react-helmet-async";
import useFetchBanners from "../../hooks/useFetchBanners";
import useBreakpoint from "../../hooks/useBreakpoints";

const description =
  "Odkryj najnowsze wiadomości ze świata mangi i anime oraz przeglądaj najświeższe wydania magazynu Otaku. Najnowsze aktualizacje i numery w jednym miejscu.";

const MainContent: React.FC = () => {
  const {
    kategorie,
    newsy,
    pagination,
    error: errorNews,
    loading: LoadingNews,
  } = useFetchNews({
    strona: 1,
    limit: 9,
    szukaj: "",
    kategorie: "",
  });
  // const { magazines, error: ErrorMagazines, loading: loadingMagazines } = useFetchMagazines();

  const { banners, error, loading: loadingBanners } = useFetchBanners();
  const isSmall = useBreakpoint(500)

  return (
    <>
      <Helmet>
        <title>Strona główna | Magazyn Otaku</title>
        <meta name="description" content={description} />
      </Helmet>
      <div>
        <MainPageBanner banery={banners} loading={loadingBanners} />
        <TitleWithSplit text="NEWSY" />
        <NewsGrid news={newsy} loading={LoadingNews} />
        <FowardButton type="news" />
        {/* <TitleWithSplit text="MAGAZYN OTAKU" /> */}
        {/* <MagazineGrid magazines={magazines} /> */}
        {/* <FowardButton type="magazyn" /> */}
      </div>
    </>
  );
};

const Main: React.FC = () => {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <MainContent />
    </Suspense>
  );
};

export default Main;
