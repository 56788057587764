import Grid from "@mui/material/Unstable_Grid2/Grid2";
import styles from "./AsideNews.module.css";
import { CategoryTag } from "../../../MainPage/CategoryTag/CategoryTag";
import useBreakpoint from "../../../../hooks/useBreakpoints";
import { Link, useNavigate } from "react-router-dom";
import { cleanAndTruncateText } from "../../../../helpers/cleanAndTruncateText";
import { NewsCategory } from "../../../../types/News";
import { useEffect, useState } from "react";
import { truncateTextByWords, cleanString } from "../../../MainPage/NewsGrid/News/News";

interface AsideNewsProps {
  tag: NewsCategory;
  cover: string;
  title: string;
  description: string;
  date: string;
  alias: string;
  id: number;
  loading: boolean;
}

export const AsideNews = ({
  cover,
  title,
  description,
  date,
  tag,
  alias,
  id,
  loading,
}: AsideNewsProps) => {
  // const navigate = useNavigate();
  // const [truncatedDescription, setTruncatedDescription] = useState<string>(cleanString(description));
  // const isSmall = useBreakpoint(400);


  // useEffect(()=>{
  //   setTruncatedDescription(isSmall?truncateTextByWords(cleanString(description), 30):)      
  // },[isSmall])

  // const handleHyperlink = () => {
  //   navigate(`/news/${alias}-${id}`);
  // };

  return (
    <aside className={styles.container}>
      <Link to={`/news/${alias}-${id}`} className={styles.link}>
      <div className={styles.imageContainer}>
        <img src={!loading?cover:"/mockup-background.jpg"} alt="" className={`${cover ? styles.image : styles.imageMockup}`} />
        <CategoryTag category={tag} size='big' />
      </div>
        <div className={styles.contentContainer}>
          <p className={`${styles.title} ${loading&&"elementLoadingState"}`}>{title}</p>
          <div className={styles.descriptionContainer}>
            <p className={`text-big ${loading&&"elementLoadingState"}`}>{cleanString(description)}</p>
          </div>
          <p className={`text-small ${styles.date} ${loading&&"elementLoadingState"}`}>{date}</p>
        </div>
      </Link>
    </aside>
  );
};

//todo: funkcjonalność gdy nie ma nowszego to pokazuje jakiś inny z tej samej kategorii